.header {
  background-color: #016311;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}

.header h1 {
  color: #4DF32E;
  text-align: center;
  font-weight: 600;
  font-size: 3rem;
  font-family: "Dancing Script", cursive;
}

.header h2 {
  color: #ffffff;
  text-align: center;
  font-weight: 400;
  font-size: 1rem;
}

.contentCountdown {
  background-image: url(https://cdn.pixabay.com/photo/2016/07/05/16/53/leaves-1498985__340.jpg);
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
}

.contentEventData {
  background-color: #4DF32E;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contentEventMap {
  background-color: #4DF32E;
  padding: 0.5rem;
}

.eventData {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eventData i {
  color: #016311;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.8rem;
  padding: 0.5rem;
}

.eventData p {
  color: #016311;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  padding: 0.5rem;
}

.eventData a {
  color: #016311;
  padding: 0;
  text-decoration: none;
}

.googleMaps {
  padding: 0.5rem;
}

.button {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border: none;
  border-radius: 1rem;
}

.countdown {
  background-color: black;
  padding: 1rem;
  margin: 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown p {
  color: #016311;
  font-size: 2rem;
  font-weight: 600;
  padding: 1rem;
  text-align: center;
}

.countdown span {
  color: yellow;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.expirednotice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expirednotice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expirednotice > p {
  font-size: 1.5rem;
}

.showcounter {
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.showcounter {
  padding: 1.5rem;
  margin: 0.2rem;
  border-radius: 0.5rem;
  color: white;
  font-size: 2rem;
  align-items: center;
}

.showcounter .countdown.danger {
  color: #ff0000;
}

.showcounter > p {
  margin: 0;
}

.showcounter > span {
  color: yellow;
  text-transform: capitalize;
  font-size: 1rem;
  line-height: 1rem;
}

@media only screen and (max-width: 649px) {
  .showcounter {
    padding: 2rem;
    display: grid;
    grid-template-columns: auto auto;
  }
}/*# sourceMappingURL=green.module.css.map */