$light: #cb62ff;
$dark:#430064;
$medium:#A239D5;
$bg: #ffffff;
$fontA: 'Dancing Script', cursive;
$fontB: 'Mulish', sans-serif;
$fontC: 'Poppins', sans-serif;
$imgBg: url(https://media.istockphoto.com/vectors/abstract-blend-modern-tech-dots-background-vector-id1224403019?k=20&m=1224403019&s=612x612&w=0&h=fsrABvt0tPeaImqbrL3f-gXblr50rIpg6YbmYOYkscY=);

.header {
  background-color: $dark;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}

.header h1 {
  color:$light;
  text-align: center;
  font-weight: 600;
  font-size: 3rem;
  font-family: $fontA;
}

.header h2 {
  color:$light;
  text-align: center;
  font-weight: 400;
  font-size: 1rem;
}

.contentCountdown {
  background-image:$imgBg;
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
}

.contentEventData { 
  background-color: $light;
  padding:2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contentEventMap {
  background-color: $light;
  padding:0.5rem;
}

.eventData {
  padding:0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode {
  padding:0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eventData i {
  color:$dark;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.8rem;
  padding: 0.5rem;
}

.eventData p {
  color:$dark;
  font-family: $fontB;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  padding:0.5rem;
}

.eventData a {
  color: $dark;
  padding: 0;
  text-decoration: none;
}

.googleMaps{
  padding: 0.5rem;
}

.button {
  background-color: $bg;
  display: flex;
  flex-direction: column;
  margin:1rem;
  border:none;
  border-radius: 1rem;
}

.countdown {
  background-color: black;
  margin:2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
}

.countdown p {
  color:$dark;
  font-size: 2rem;
  font-weight: 600;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.countdown span {
  color:yellow;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: none;
}

.expirednotice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expirednotice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expirednotice > p {
  font-size: 1.5rem;
}

.showcounter {
  background-color: #ff0000;
  margin:5rem;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.showcounter {
  padding: 1.5rem;
  margin: 0.2rem;
  border-radius: 0.5rem;
  color:white;
  font-size: 2rem;
  align-items: center;
}

.showcounter .countdown.danger {
  color: #ff0000;
}

.showcounter > p {
  margin: 0;
}

.showcounter > span {
  color:yellow;
  text-transform: capitalize;
  font-size: 1rem;
  line-height: 1rem;
}


@media only screen and (max-width: 649px) {
  .showcounter {
    padding: 2rem;
    display: grid;
    grid-template-columns: auto auto;
  }
}
