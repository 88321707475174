* {
  margin: 0;
  font-family: Poppins, sans-serif;
}

.header {
  background-color: #8C52FF;
  color: #FFAAAA;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.logo {
  width: 20%;
}

.logoTemplate {
  max-width: 12%;
}

.headerTemplate {
  background-color: #8C52FF;
  color: #FFAAAA;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.headerMenu li {
  list-style-type: none;
  padding: 0.5rem;
  font-size: 1rem;
}

.headerMenu a {
  color: #FFAAAA;
  text-decoration: none;
}

.headerTemplateMenu a {
  color: #FFAAAA;
  text-decoration: none;
  font-size: 1rem;
}

.headerButton button {
  background-color: #FFAAAA;
  border: 1px;
  border-radius: 0.5rem;
  font-size: 1rem;
  margin: 0.5rem;
  padding: 0.8rem;
}

.headerButton a {
  color: white;
  text-decoration: none;
}

.headerButtonTemplate button {
  background-color: #FFAAAA;
  color: white;
  border: 1px;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  margin: 0.5rem;
  padding: 0.5rem;
  border-color: #e4e4e4;
}

.headerButtonTemplate a {
  color: white;
  text-decoration: none;
}

.headerSocialIcons a {
  color: white;
  font-size: 1.5rem;
  text-decoration: none;
}

.headerSocialIcons a:hover {
  color: #FFAAAA;
  font-size: 1.5rem;
}

.banner {
  background-color: #8C52FF;
  color: white;
  padding: 5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bannerTitle {
  color: white;
  font-size: 2.5rem;
  padding: 1rem;
  text-align: center;
}

.bannerSubTitle {
  color: white;
  font-size: 1.5rem;
  padding: 1rem;
  text-align: center;
}

.bannerSpan {
  color: white;
  font-size: 1rem;
  padding: 0.5rem;
  text-align: center;
}

.homepageTitle {
  padding: 2rem;
  text-align: center;
}

.homepageTitle i {
  color: red;
  font-size: 1.5rem;
}

.homepageBenef {
  padding: 1rem;
  display: grid;
  grid-template-columns: auto auto auto;
}

.homepageBenef li {
  padding: 1rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.homepageBenef i {
  color: #8C52FF;
  font-size: 2rem;
  align-items: center;
  padding: 2rem;
}

.homepageBonus {
  background-color: #8C52FF;
  margin: 0;
  padding: 1rem;
}

.homepageBonusList {
  background-color: #8C52FF;
  margin: 0;
  padding: 1rem;
  display: grid;
  grid-template-columns: auto auto auto;
}

.homepageBonusList li {
  padding: 1rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.homepageBonus i {
  color: white;
  font-size: 2rem;
  align-items: center;
  padding: 2rem;
}

.homepageBonus h2 {
  color: white;
  font-size: 2rem;
  font-weight: 600;
  margin-top: 0.5rem;
  text-align: center;
}

.homepageBonus h3 {
  color: white;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  align-items: center;
}

.homepageBonus p {
  color: white;
  font-size: 0.9rem;
  align-items: center;
}

.homepageComoFunciona {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.homepageComoFunciona h2 {
  color: #8C52FF;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
}

.homepageComoFunciona li {
  padding: 0.4rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.homepageComoFunciona i {
  color: #13AD0A;
  font-size: 1.5rem;
  align-items: center;
  padding: 0.5rem;
}

.homepageCompra {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #FFAAAA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homepageCompra h2 {
  color: #333333;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.homepageCompra h3 {
  color: blue;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 4rem;
  font-weight: 600;
}

.homepageCompra h4 {
  color: red;
  text-decoration: line-through;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.7rem;
  font-weight: 600;
}

.homepageCompra p {
  font-family: "Poppins", sans-serif;
  color: #333333;
  padding: 0.8rem;
  text-align: center;
}

.homepageCompra button {
  background-color: #13AD0A;
  width: 45%;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  border: none;
  border-radius: 1rem;
  padding: 1rem;
  margin: 1.5rem;
  box-shadow: 0.2rem 0.2rem #333333;
}

.homepageCompra button a {
  color: white;
  text-decoration: none;
  font-size: 1.3rem;
  font-family: Arial, Helvetica, sans-serif;
}

.homepageModelos {
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homepageModelos h2 {
  color: #8C52FF;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
}

.homepageModelos p {
  color: #333333;
  text-align: center;
  padding: 1rem;
}

.homepageModelos button {
  background-color: #8C52FF;
  width: 25%;
  color: white;
  border: none;
  border-radius: 1rem;
  padding: 1rem;
  margin: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  box-shadow: 0.2rem 0.2rem #333333;
}

.homepageModelos button a {
  color: white;
  text-decoration: none;
  font-size: 1.3rem;
  font-family: Arial, Helvetica, sans-serif;
}

.homepageFaq {
  background-color: #8C52FF;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.homepageFaq h2 {
  color: white;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}

.homepageFaq li {
  color: white;
  padding: 0.4rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.homepageFaq i {
  color: white;
  font-size: 1.5rem;
  align-items: center;
  padding: 0.5rem;
}

.homepageFaq p {
  color: white;
  font-size: 0.9rem;
  text-align: center;
}

.faleConosco {
  background-color: #8C52FF;
  color: white;
  padding: 2rem;
  display: flex;
  justify-content: center;
}

.footer {
  background-color: black;
  padding: 2rem;
}

.footerContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footerContentSection {
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerContentSection button {
  margin: 1.5rem;
  background-color: #8C52FF;
  color: white;
  font-size: 1.5rem;
  border: none;
  border-radius: 1rem;
}

.footerContentSection h2 {
  color: #8C52FF;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
}

.footer li {
  padding: 0.1rem;
  list-style-type: none;
  color: white;
  font-size: 0.9rem;
  text-align: center;
}

.footerRights p {
  color: white;
  text-align: center;
  font-size: 0.8rem;
}

.footerTemplate {
  background-color: black;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modelos {
  padding: 2rem;
  text-align: center;
  margin-bottom: 5rem;
}

.modelos h2 {
  color: #333333;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.4rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.modelosGrid {
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.modelosGridImg {
  width: 90%;
  height: 90%;
  border-radius: 2rem;
  box-shadow: 0.2rem 0.2rem #333333;
}

.modelosGrid li {
  list-style-type: none;
  padding: 1rem;
  margin-top: 1rem;
}

.modelosGrid a {
  color: #8C52FF;
  font-weight: 500;
  font-size: 1rem;
  text-decoration: none;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}

.modelosGrid button {
  background-color: #8C52FF;
  border: none;
  border-radius: 1rem;
  padding: 0.5rem;
}

.modelosGrid button a {
  color: white;
  text-decoration: none;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}

@media only screen and (max-width: 649px) {
  .header {
    padding: 0.5rem;
    background-color: #8C52FF;
    color: #FFAAAA;
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: flex-start;
  }
  .logo {
    width: 30%;
  }
  .headerTemplate {
    background-color: #8C52FF;
    color: #FFAAAA;
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: flex-start;
  }
  .logoTemplate {
    max-width: 20%;
  }
  .banner {
    background-color: #8C52FF;
    color: white;
    padding: 3rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .homepageBenef {
    padding: 1rem;
    display: grid;
    grid-template-columns: auto auto;
  }
  .homepageBonusList {
    background-color: #8C52FF;
    margin: 0;
    padding: 1rem;
    display: grid;
    grid-template-columns: auto;
  }
  .homepageModelos button {
    background-color: #8C52FF;
    width: 20%;
    color: white;
    border: none;
    border-radius: 1rem;
    padding: 1rem;
    margin: 1.5rem;
  }
  .homepageModelos button a {
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
    font-family: Arial, Helvetica, sans-serif;
  }
  .modelosGrid {
    display: grid;
    grid-template-columns: auto auto;
  }
  .modelosGrid li {
    list-style-type: none;
    padding: 1rem;
    margin-top: 2rem;
  }
  .footerContent {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
  }
}
@media only screen and (max-width: 499px) {
  .header {
    padding: 0.5rem;
    background-color: #8C52FF;
    color: #FFAAAA;
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: flex-start;
  }
  .logo {
    width: 40%;
  }
  .headerTemplate {
    padding: 0.5rem;
    background-color: #8C52FF;
    color: #FFAAAA;
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: flex-start;
  }
  .logoTemplate {
    max-width: 20%;
  }
  .banner {
    background-color: #8C52FF;
    color: white;
    padding: 2rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .homepageBenef {
    padding: 1rem;
    display: grid;
    grid-template-columns: auto auto;
  }
  .homepageModelos button {
    background-color: #8C52FF;
    width: 30%;
    color: white;
    border: none;
    border-radius: 1rem;
    padding: 1rem;
    margin: 1.5rem;
  }
  .homepageModelos button a {
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
    font-family: Arial, Helvetica, sans-serif;
  }
  .modelosGrid {
    display: grid;
    grid-template-columns: auto;
  }
  .footerContent {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
  }
  .footer {
    background-color: black;
    padding: 1rem;
  }
  .footerContentSection {
    margin: 1 rem;
  }
}
@media only screen and (max-width: 399px) {
  .header {
    padding: 0.5rem;
    background-color: #8C52FF;
    color: #FFAAAA;
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: flex-start;
  }
  .logo {
    width: 60%;
  }
  .headerTemplate {
    background-color: #8C52FF;
    color: #FFAAAA;
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: flex-start;
  }
  .logoTemplate {
    max-width: 40%;
  }
  .banner {
    background-color: #8C52FF;
    color: white;
    padding: 2rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .homepageBenef {
    padding: 1rem;
    display: grid;
    grid-template-columns: auto auto;
  }
  .homepageModelos button {
    background-color: #8C52FF;
    width: 40%;
    color: white;
    border: none;
    border-radius: 1rem;
    padding: 1rem;
    margin: 1.5rem;
  }
  .homepageModelos button a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
  }
  .modelosGrid {
    display: grid;
    grid-template-columns: auto;
  }
  .footerContent {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
  }
  .footer {
    background-color: black;
    padding: 1rem;
  }
  .footerContentSection {
    margin: 1rem;
  }
} /*# sourceMappingURL=App.module.css.map *//*# sourceMappingURL=App.module.css.map */